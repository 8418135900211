<template>
  <div>
  <a-layout id="components-layout-demo-fixed">
    <a-layout-header  :class="{ color: 'color' }" :style='style'>
        <Header></Header>
    </a-layout-header>
    <a-layout-content :style="{ marginTop: '64px',background:'#f6f6f6' }">
      <transition name='slide-fade'>
        <router-view> </router-view>
      </transition>
    </a-layout-content>
    <a-layout-footer v-if="footer_show">
      <Footer></Footer>
    </a-layout-footer>
  </a-layout>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
  // 这是 home 页面的 ‘我们信息’ 的组件
  name: 'home',
  data () {
    return {
      routeKsy: '',
      routeIndex: '',
      footer_show: true,
      style: {
        position: 'fixed',
        zIndex: 1,
        width: '100%',
        background: '#fff',
        borderBottom: 'none',
        boxShadow: '',
        zIndex: '99'
      },
      color: false
    }
  },
  components: {
    Header,
    Footer
  },
  watch: {
    $route: function (to) {
      // console.log('route',to)
      if (to) {
        this.routeKsy = to.name
        sessionStorage.setItem('routeKsy', to.name)
        if (to.name === 'bookDetail' || to.name === 'bookRecommend') {
          this.footer_show = false
        } else {
          this.footer_show = true
        }
      }
    },
    routeKsy: function () {
      this.routeKsy = sessionStorage.getItem('routeIndex')
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      // let offsetTop = document.querySelector(".header").offsetTop;
      // 设置背景颜色的透明读 0px 5px 3px #ccc
      if (scrollTop) {
        this.style.boxShadow = '0px 5px 3px rgba(204,204,204,.2)'
        this.color = true
      }
    },
    footer (bool) {
      this.footer_show = bool
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>

<style lang="less" scoped="scoped">
 .ant-layout-header,.ant-layout-content,.ant-layout-footer{
  margin: 0;padding: 0;
  background: #FFF;
}
.ant-layout-header {
  height: 64px;
  border-bottom:1px solid rgba(61,61,61,.15);
  box-sizing: border-box;
}
.ant-layout-content{
  // min-height: calc(100vh-'64px');
}
// .ant-layout-footer{
//   height: 360px;
//   background: #FFFFFF !important;
// }
//规定到屏幕最小宽度为 960px
// 小于960 会出现滚动条
#app{
  min-width:960px;
}
*{
  font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;
}
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  // transform: translateX(30px);
  opacity: 0;
}
</style>
